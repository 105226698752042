<template>
  <div class="p-2 mb-1">
    <strong class="d-block mb-1">Text</strong>
    <div class="input-group mb-3">
      <input type="text" class="form-control border-right-0" placeholder="Insert text" v-model="text" v-on:keydown.enter="addText">
      <button class="btn btn-outline-secondary rounded-0" type="button" v-on:click="addText">Add</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextVariants',
  data(){
    return {
      text: '',
      textOptions:{},
      selectedFont: ''
    }
  },
  methods:{
    addText(){
      this.$emit('addText', {
        text:this.text,
        font: this.selectedFont,
        size: 24
      });
      this.text = '';
    }
  },
  mounted(){
    let config = this.$store.getters.config;
    this.textOptions = config.textOptions;
    this.selectedFont = this.textOptions.fonts[0];
  }
};
</script>