<template>
  <div  class="card mb-3 mt-3">
    <h5 class="card-header d-flex justify-content-between">
      Cart
      
    </h5>
    <div v-if="orderLines.length > 0">
      <div class="orderLine border-bottom" v-for="orderLine in orderLines" :key="orderLine.id">
        
        <div class="card-body p-2 position-relative">
          <div class="btn-close" v-on:click="deleteOrderLine(orderLine)">×</div>
          <div class="row">
        <div class="col-md-4">
          <div class="orderLine-image position-relative">
            <img :src="orderLine.image" class="img-fluid rounded-start">
          </div>
        </div>
        <div class="col-md-8">
            <p class="mb-0"> {{ orderLine.product.name }}</p>
            <small class="d-block text-muted">{{ orderLine.color.name }} / {{ orderLine.size.name }}</small>
            <div class="row my-2 d-none">
              <div class="col-6" v-if="orderLine.texts.length">
                <small class="d-block text-muted">Texts</small>
                <ul class="list-group list-group-flush my-1 small text-muted">
                  <li class="list-group-item border-0 py-0 px-1" v-for="(text, index) in orderLine.texts" :key="`text-${index}`">
                    {{ text.text }}
                  </li>
                </ul>
              </div>
              <div class="col-6" v-if="orderLine.graphics.length">
                <small class="d-block text-muted">Graphics</small>
                <ul class="list-group list-group-flush my-1 small text-muted">
                  <li class="list-group-item border-0 py-0 px-1" v-for="(graphic, index) in orderLine.graphics" :key="`gfx-${index}`">
                    <img :src="graphic.image" width="20">
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <small class="d-block text-muted">{{ orderLine.quantity }} x {{ getOrderLineUnitPrice(orderLine) }}</small>
            </div>
          </div>
          </div>
        </div>
      </div>
      
      <div class="card-footer d-flex justify-content-between border-top-0 p-2">
        <span class="small text-muted ">Subtotal</span>
        <span class="small text-muted">{{ total }}</span>
      </div>
      <div class="card-footer d-flex justify-content-between p-2">
        <span class="small text-muted ">Shipping</span>
        <span class="small text-muted">{{ shipping }}</span>
      </div>
      <div class="card-footer d-flex justify-content-between p-2" v-if="!pricesIncludeVAT">
        <span class="small text-muted ">VAT ({{ getVAT }}%)</span>
        <span class="small text-muted">{{ vat }}</span>
      </div>
      <div class="card-footer d-flex justify-content-between p-2">
        <span class="small text-muted font-weight-bold">Total <span v-if="pricesIncludeVAT">(incl. VAT)</span></span>
        <span class="small text-muted font-weight-bold">{{ totalWithVATAndShipping }}</span>
      </div>
      <div class="card-footer d-flex justify-content-between p-2" v-if="context !== 'checkout'">
        <button type="button" class="btn-success btn-lg btn-block" v-on:click="goToCheckout">Checkout</button>
      </div>
    </div>
    <div class="p-2" v-else>
      <p class="mb-0 text-center text-muted small">No products in cart</p>
    </div>
  </div>
</template>

<script>
import { utils } from '@/utils';
export default {
  name: 'Cart',
  props:{
    context: {
      default: 'page',
      type: String
    }
  },
  computed:{
    pricesIncludeVAT(){
      return this.$store.getters.config.ecommerce.pricesInclVAT;
    },
    orderLines(){
      return this.$store.getters.orderLines;
    },
    getTotal(){

      return this.orderLines.reduce( (total, orderLine) =>{
        return total + orderLine.price;
      }, 0);
    },
    getVAT(){
      return this.$store.getters.config.ecommerce.VAT;
    },
    getShipping(){
      return this.$store.getters.config.ecommerce.shipping;
    },
    getVATPrice(){
      let VAT = this.getVAT;
      let price = this.getTotal;
      return price * (VAT / 100);
    },
    total(){
      let currency =this.$store.getters.config.ecommerce.currency;
      let price = this.getTotal;
      
      return utils.formatPrice((price), currency);
    },
    vat(){
      let currency =this.$store.getters.config.ecommerce.currency;
      return utils.formatPrice(this.getVATPrice, currency);
    },
    shipping(){
      let currency = this.$store.getters.config.ecommerce.currency;
      return utils.formatPrice(this.getShipping.price, currency);
    },
    totalWithVATAndShipping(){
      let shipping = this.getShipping;
      let currency = this.$store.getters.config.ecommerce.currency;
      let price = this.getTotal;
      let VAT = this.pricesIncludeVAT ? 0 : this.getVATPrice;
      
      return utils.formatPrice(price + VAT + shipping.price, currency);
    }
  },
  methods:{
    goToCheckout(){
      this.$emit('goToCheckout');
    },
    deleteOrderLine(orderLine){
      this.$store.commit('removeOrderLine', orderLine.id);
    },
    getOrderLineUnitPrice(orderLine){
      let currency = this.$store.getters.config.ecommerce.currency;
      return utils.formatPrice(orderLine.unitPrice, currency);
    }
  }
};
</script>