<template>
  <div class="p-2 mb-1">
    <strong class="d-block mb-1">Graphics</strong>
    <div class="d-flex flex-wrap">
      <span class="graphic-item" v-for="(gfx, index) in graphics" :key="'gfx'+index" v-on:click="addGraphic(index)" >
        <img :src="gfx">
      </span>
    </div>
    <div class="form-group border-top pt-2">
      <div class="mb-3">
        <label for="uploadGfx" class="form-label">
          Upload custom image
          
        </label>
        <input class="form-control h-auto" type="file" accept="image/*" id="uploadGfx" v-on:change="uploadGraphic">
        <div class="preview mt-1" v-if="previewImage" >
          <img class="img-fluid" :src="previewImage" />
        </div>
        <div class="alert alert-info small d-block text-muted font-weight-bold">
          <ul class="pl-1 mb-0">
            <li>Custom graphics cannot change color</li>
            <li>Svg format is recommended</li>
          </ul>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GraphicVariants',
  props:['graphics'],
  data(){
    return{
      previewImage: ''
    }
  },
  methods:{
    addGraphic(index){
      this.$emit('addGraphic', index);
    },
    uploadGraphic(event){
      const image = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) =>{
        this.previewImage = e.target.result;
        this.$emit('addOwnGraphic', this.previewImage);
      };
    }
  }
}
</script>