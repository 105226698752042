import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    productData:{},
    variantCombinations:{},
    config:{},
    orderLines:[],
    canvas:null
  },
  getters:{
    productData(state){
      return state.productData;
    },
    variantCombinations(state){
      return state.variantCombinations;
    },
    config(state){
      return state.config;
    },
    orderLines(state){
      return state.orderLines;
    },
    canvas(state){
      return state.canvas;
    }
  },
  mutations: {
    productData(state, payload){
      state.productData = payload;
    },
    variantCombinations(state, payload){
      state.variantCombinations = payload;
    },
    config(state, payload){
      state.config = payload;
    },
    addOrderLine(state, payload){
      state.orderLines.push(payload);
    },
    removeOrderLine(state, payload){
      state.orderLines = state.orderLines.filter(ol => ol.id !== payload);
    },
    updateCanvas(state, payload){
      state.canvas = payload;
    }
  },
  actions: {
    saveDesign(context, payload){
      let storedData = localStorage.getItem('item.designer.savedDesigns');
      let designs = [];
      if(storedData !== null){
        designs = JSON.parse(storedData);
      }
      designs.push(payload);
      localStorage.setItem('item.designer.savedDesigns', JSON.stringify(designs));
    }
  }
})
