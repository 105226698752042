<template>
  <div class="btn-toolbar">
    <div class="d-flex">
      <input class="form-control" type="color" v-model="selectedColor">
      <div class="input-group">
        <input type="number" class="form-control" v-model="selectedSize" v-on:change="setSize">
        <div class="input-group-append">
          <div class="input-group-text">px</div>
        </div>
      </div>
      <select class="custom-select" v-model="selectedFont" v-on:change="setFont">
        <option :value="font" v-for="(font, index) in textOptions.fonts" :key="'font'+index">
          {{ font }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { utils } from '@/utils';
export default {
  name: 'TextToolbar',
  data(){
    return{
      textOptions:{},
      selectedSize: 24,
      selectedFont: 'Arial',
      selectedColor: '#1f1f1f'
    }
  },
  methods:{
    setFont(){
      this.$emit('setFont', this.selectedFont );
    },
    setSize(){
      this.$emit('setSize', this.selectedSize );
    }
  },
  watch:{
    selectedColor(){
      this.$emit('setColor', this.selectedColor );
    }
  },
  mounted(){
    let config = this.$store.getters.config;
    const fontFamilies = utils.getFontFamilies();
    console.log(fontFamilies);
    this.textOptions = config.textOptions;
    this.textOptions.fonts = fontFamilies;
    
    this.selectedFont = "Arial";
  }
};
</script>
