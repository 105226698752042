<template>
  <nav class="navbar navbar-light bg-light">
    <button class="btn btn-outline-warning" type="button" v-on:click="reset">Reset design</button>
    <button class="btn btn-outline-success" type="button" v-on:click="save">Save design for later</button>
  </nav>
</template>

<script>
export default {
  name: 'ActionsToolbar',
  methods:{
    reset(){
      this.$emit('resetDesign');
    },
    save(){
      this.$emit('saveDesign');
    }
  }
};
</script>