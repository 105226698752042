<template>
  <div id="app">
    <Loader v-if="loading" />
    <div class="container" v-if="ready">
      <div class="row d-block">
        <div class="col-3 float-left">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a :class="openTab === 'config' ? 'nav-link active' : 'nav-link'" href="#"
                 v-on:click="setOptionsTab('config')">
                Configuration
              </a>
            </li>
            <!--
            <li class="nav-item">
              <a :class="openTab === 'customize' ? 'nav-link active' : 'nav-link'" href="#"
                 v-on:click="setOptionsTab('customize')">
                Customize
              </a>
            </li>
            -->
          </ul>
          <div class="border border-top-0 p-2">
            <TypeVariants v-if="openTab === 'config'"
                          :types="types"
                          :selectedType="selectedType"
                          @changeType="changeType"
            />
            <ColorVariants v-if="openTab === 'config'"
                           :variants="variantColors"
                           :selectedVariant="selectedVariant.color"
                           @changeColorVariant="changeColorVariant"
            />
            <SizeVariants v-if="openTab === 'config'"
                          :variants="variantSizes"
                          :selectedVariant="selectedVariant.size"
                          @changeSizeVariant="changeSizeVariant"
            />
            <hr />
            <h5>Customize</h5>
            <TextVariants v-if="openTab === 'config'"
                          @addText="addText"

            />

            <GraphicVariants v-if="openTab === 'config'"
                             :graphics="graphics"
                             @addGraphic="addGraphic"
                             @addOwnGraphic="addOwnGraphic"
            />

          </div>
        </div>
        <div class="col-6 float-left">
          <Canvas :options="canvasOptions"
                  :image="selectedVariant.color.image"
                  :addedGraphics="addedGraphics"
                  :addedTexts="addedTexts"
                  :selectedType="selectedType"
                  :readyForCanvas="readyForCanvas"
                  :canvasIsLoading="canvasIsLoading"
                  @resetDesign="clearSelectedItems"
                  
          />
        </div>
        <div class="col-3 float-left">
          <Form 
          :selectedType="selectedType"
          :selectedVariant="selectedVariant"
          :variantCombos="variantCombos"
          :addedTexts="addedTexts"
          :addedGraphics="addedGraphics"
          @resetDesign="clearSelectedItems"
          @toggleCanvasLoading="canvasLoader"
          />
          <Cart
              @goToCheckout="checkoutShow = true"
          />
        </div>
      </div>
    </div>
    <Checkout :checkoutShow="checkoutShow" @dismissCheckout="checkoutShow = !checkoutShow" />
    <div :class="`modal-backdrop fade ${checkoutShow ? 'show' : 'd-none'}`"></div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import ColorVariants from '@/components/VariantComponents/Colors';
import TypeVariants from '@/components/VariantComponents/Types';
import GraphicVariants from '@/components/VariantComponents/Graphics';
import TextVariants from '@/components/VariantComponents/Texts';
import SizeVariants from '@/components/VariantComponents/Sizes';
import Canvas from '@/components/Canvas';
import Form from '@/components/Form';
import Cart from '@/components/Cart';
import Checkout from '@/components/Checkout';


export default {
  name: 'App',
  components: {
    Loader,
    ColorVariants,
    TypeVariants,
    SizeVariants,
    Canvas,
    Form,
    Cart,
    Checkout,
    GraphicVariants,
    TextVariants
  },
  data() {
    return {
      ready: false,
      loading: true,
      canvasIsLoading: false,
      readyForCanvas: false,
      optionsTab: 'config',
      selectedVariant: {
        color: {
          image: 'https://leapinleachiesshop.com/wp-content/uploads/2016/07/product-placeholder.jpg'
        },
        size: ''
      },
      selectedType: {},
      canvasOptions: {
        bounds: {}
      },
      types: [],
      product: {},
      variantColors: {},
      variantSizes: {},
      variantCombos: {},
      addedGraphics: [],
      addedTexts: [],
      graphics: [],
      checkoutShow: false
    };
  },
  computed: {
    openTab() {
      return this.optionsTab;
    }
  },
  methods: {
    canvasLoader(){
      this.canvasIsLoading = !this.canvasIsLoading;
    },
    setOptionsTab( value ) {
      this.optionsTab = value;
    },
    changeType(type){
      this.loading = true;
      this.setConfig(type);
      this.setProductData();
    },
    changeColorVariant( option ) {
      this.selectedVariant.color = option;
      this.filterSizeVariants();
    },
    changeSizeVariant( option ) {
      this.selectedVariant.size = option;
    },
    addText( objText ) {
      this.addedTexts.push(
          {
            'text': objText.text,
            x: 50,
            y: 50,
            width: 50,
            height: 50,
            options: {
              fontSize: objText.size,
              fontFamily: objText.font
            }
          }
      );
    },
    addOwnGraphic(graphic){
      this.graphics.push(graphic);
      this.addGraphic(this.graphics.length - 1);
    },
    addGraphic( index ) {
      let isCustom = this.graphics[ index ].startsWith('data:image');
      this.addedGraphics.push( {
        image: this.graphics[ index ],
        x: 50,
        y: 50,
        width: 50,
        height: 50,
        isCustom: isCustom
      } );
    },
    filterSizeVariants() {
      let vm = this;
      this.readyForCanvas = false;
      this.variantSizes.options.forEach( ( size ) => {
        let selectedColor = this.selectedVariant.color;
        let variantId = [ selectedColor.id, size.id ].join( '.' );
        size.active = this.variantCombos.filter( vc => vc.variantId === variantId ).length !== 0;
      } );
      this.ready = true;
      setTimeout(() =>{
        vm.readyForCanvas = true;
      }, 100);
    },
    setConfig(selectedType) {
      let config = this.$store.getters.config;
      this.graphics = config.graphics;
      this.types = config.types;
      this.selectedType = this.types.find(t => t.value === selectedType) || this.types[ 0 ];
      this.canvasOptions.bounds = config.canvas.bounds[ this.selectedType.value ];
    },
    setProductData() {
      let productData = this.$store.getters.productData;
      let variantCombinations = this.$store.getters.variantCombinations;
      this.product = productData.find( p => p.type === this.selectedType.value );
      this.variantColors = this.product.variantGroups.colors;
      this.variantSizes = this.product.variantGroups.sizes;
      this.variantCombos = variantCombinations[this.product.type];
      this.selectedVariant.color = this.variantColors.options[0];
      this.selectedVariant.size = this.variantSizes.options[0];
      this.loading = false;
    },
    clearSelectedItems(){
      this.addedGraphics = [];
      this.addedTexts = [];
    }
  },
  watch:{
    selectedType(){
      this.clearSelectedItems();
    }
  },
  mounted() {
    this.setConfig();
    this.setProductData();
    this.filterSizeVariants();
    
  }
};
</script>