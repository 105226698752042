<template>
  <div :class="`loader-container ${positionClass}`">
    <div class="loader"><div></div><div></div></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    position: {
      default: 'fixed',
      type: String
    }
  },
  computed:{
    positionClass(){
      return `position-${this.position}`;
    }
  }
};
</script>