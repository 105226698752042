<template>
  <div class="bg-light border h-100 position-relative">
    <Loader v-if="canvasIsLoading" position="absolute" />
    <TextToolbar v-if="showTextToolbar"
                 @setFont="setFontFamily"
                 @setColor="setFontColor"
                 @setSize="setFontSize"
    />
    <GraphicsToolbar v-if="showGraphicsToolbar" @setGraphicColor="setGraphicsColor"/>
    <div class="fabric-canvas-container">
      <div id="canvas-base" class="canvas" v-if="image">
        <img class="canvas-image" :src="image" v-on:load="onCanvasImageLoad">
        <div class="canvas-bounds"
             :style="'width:'+options.bounds.width + '%;height:'+options.bounds.height+'%;top:'+options.bounds.top+'%;left:'+options.bounds.left+'%;'">
          <canvas id="canvas" ref="canvas"/>
        </div>
      </div>
      
    </div>
    <ActionsToolbar
      @resetDesign="resetCanvas"
      @saveDesign="saveDesign"
    />
  </div>
</template>

<script>

import { fabric } from 'fabric';
import Loader from '@/components/Loader';
import TextToolbar from '@/components/CanvasComponents/TextToolbar';
import GraphicsToolbar from '@/components/CanvasComponents/GraphicsToolbar';
import ActionsToolbar from '@/components/CanvasComponents/ActionsToolbar';

export default {
  name: 'Canvas',
  props: [ 'options', 'image', 'addedGraphics', 'addedTexts', 'textOptions', 'selectedType', 'readyForCanvas', 'canvasIsLoading' ],
  components:{
    Loader,
    TextToolbar,
    GraphicsToolbar,
    ActionsToolbar
  },
  data() {
    return {
      canvas: null,
      activeObject:null
    };
  },
  computed: {
    showTextToolbar(){
      return this.activeObject && this.activeObject.id.indexOf('txt') > -1;
    },
    showGraphicsToolbar(){
      return this.activeObject && this.activeObject.id.indexOf('gfx') > -1;
    }
  },
  methods: {
    saveDesign(){
      let canvas = this.$store.getters.canvas;
      this.$store.dispatch('saveDesign', canvas);
    },
    createCanvas(){
      const canvasBounds = document.querySelector( '.canvas-bounds' );
      let canvas = new fabric.Canvas( this.$refs.canvas, {
            width: canvasBounds.clientWidth,
            height: canvasBounds.clientHeight,
            hoverCursor: 'pointer',
            selection: true,
            selectionBorderColor: 'blue'
          }
      );
      this.$store.commit('updateCanvas', canvas);
    },
    loadCanvas() {
      let canvas = this.$store.getters.canvas;
      canvas.on( {
        'object:moving': ( event ) => {
          event.target.opacity = 0.5;
        },
        'mouse:down': ( event ) => {
          this.activeObject = event.target;
        },
        'object:modified': ( event ) => {
          event.target.opacity = 1;
          let ref = event.target.id;
          if ( ref ) {
            let split = ref.split( '.' );
            let id = parseInt( split[ 1 ] );
            if ( split[ 0 ] === 'gfx' ) {
              
              Object.assign( this.addedGraphics[ id ],
                  {
                    height: event.target.height * event.target.scaleY,
                    width: event.target.width * event.target.scaleX,
                    x: event.target.left,
                    y: event.target.top,
                    _objects: event.target._objects
                  }
              );
            }
            else if ( split[ 0 ] === 'txt' ) {
              Object.assign( this.addedTexts[ id ],
                  {
                    height: event.target.height * event.target.scaleY,
                    width: event.target.width * event.target.scaleX,
                    x: event.target.left,
                    y: event.target.top,
                    fill: event.target.fill,
                    fontFamily: event.target.fontFamily,
                    fontSize: event.target.fontSize,
                  }
              );
            }
          }
        }
      } );
      this.renderCanvas();
      
    },
    updateCanvas(canvas) {
      this.$store.commit('updateCanvas', canvas);
    },
    renderCanvas() {
      let canvas = this.$store.getters.canvas;
      const currentObjects = canvas.getObjects().map(function(o) {
        return o.set('active', true);
      });
      this.addedGraphics.forEach( ( graphic, index ) => {
        if(currentObjects.filter(o => o.id === `gfx.${index}`).length === 0){
          this.addGraphic( graphic, index );
        }
      } );
      this.addedTexts.forEach( ( text, index ) => {
        if(currentObjects.filter(o => o.id === `txt.${index}`).length === 0) {
          this.addText( text, index );
        }
      } );
      this.resizeCanvas();
      canvas.renderAll();
    },
    resizeCanvas() {
      let canvas = this.$store.getters.canvas;
      if(canvas !== null){
        const canvasBounds = document.querySelector( '.canvas-bounds' );
        const containerWidth = canvasBounds.clientWidth;
        const containerHeight = canvasBounds.clientHeight;
        const ratio = containerWidth / containerHeight;
        const scale = containerWidth / canvas.getWidth();
        const zoom = canvas.getZoom() * scale;
        canvas.setDimensions( { width: containerWidth, height: containerWidth / ratio } );
        canvas.setViewportTransform( [ zoom, 0, 0, zoom, 0, 0 ] );
      }
      
    },
    resetCanvas(){
      let canvas = this.$store.getters.canvas;
      canvas.clear();
      this.$emit('resetDesign');
    },
    onCanvasImageLoad(){
      let vm = this;
      setTimeout(() =>{
        vm.resizeCanvas();
      }, 100);
    },
    addGraphic( graphic, index ) {
      let canvas = this.$store.getters.canvas;
      let isCustom = graphic.image.startsWith('data:image');
      let isCustomPng = isCustom && graphic.image.startsWith('data:image/png;base64');
      let isCustomSvg = isCustom && graphic.image.startsWith('data:image/svg+xml;base64');
      if(isCustomPng){
        fabric.Image.fromURL( graphic.image, ( img ) => {
              img.set( {
                left: graphic.x,
                top: graphic.y
              } );
              img.scaleToHeight( graphic.height );
              img.scaleToWidth( graphic.width );
              img.id = 'gfx.' + index;
              img.isCustom = true;
              canvas.add( img );
              this.updateCanvas(canvas);
            },
            { crossOrigin: 'anonymous' } );
      } else{
        fabric.loadSVGFromURL( graphic.image, ( objects, options ) => {
          let shape = fabric.util.groupSVGElements(objects, options);

          shape.set( {
            left: graphic.x,
            top: graphic.y
          } );
          shape.scaleToHeight( graphic.height );
          shape.scaleToWidth( graphic.width );
          shape.id = 'gfx.' + index;
          if(isCustomSvg){
            shape.isCustom = true;
          }
          canvas.add( shape );

          this.updateCanvas(canvas);
        });
      }
      
    },
    setGraphicsColor(strColor){
      let canvas = this.$store.getters.canvas;
      let activeObj = canvas.getActiveObject();
      if(activeObj && activeObj.id.indexOf('gfx') > -1){
        activeObj.set({fill: strColor});
        if (activeObj._objects) {
          for (var i = 0; i < activeObj._objects.length; i++) {
            activeObj._objects[i].set({
              fill: strColor
            });
          }
        }
      }
      this.updateCanvas(canvas);
      canvas.renderAll();
    },
    setFontFamily(strFont){
      let canvas = this.$store.getters.canvas;
      let activeObj = canvas.getActiveObject();
      if(activeObj && activeObj.id.indexOf('txt') > -1){
        activeObj.set('fontFamily', strFont);
      }
      this.updateCanvas(canvas);
      canvas.renderAll();
    },
    setFontColor(strColor){
      let canvas = this.$store.getters.canvas;
      let activeObj = canvas.getActiveObject();
      if(activeObj && activeObj.id.indexOf('txt') > -1){
        activeObj.set('fill', strColor);
      }
      this.updateCanvas(canvas);
      canvas.renderAll();
    },
    setFontSize(intSize){
      let canvas = this.$store.getters.canvas;
      let activeObj = canvas.getActiveObject();
      const id = parseInt(activeObj.id.split('.')[1]);
      if(activeObj && activeObj.id.indexOf('txt') > -1){
        activeObj.set('fontSize', intSize);
      }
      this.addedTexts[id].fontSize = activeObj.fontSize;
      this.updateCanvas(canvas, activeObj);
      canvas.renderAll();
    },
    addText( textObj, index ) {
      let canvas = this.$store.getters.canvas;
      const text = new fabric.IText( textObj.text, textObj.options );
      text.id = 'txt.' + index;
      canvas.add( text );
      this.updateCanvas(canvas);
    }
  },
  watch: {
    addedGraphics() {
      this.renderCanvas();
    },
    addedTexts() {
      this.renderCanvas();
    },

    selectedType(){
      let canvas = this.$store.getters.canvas;
      canvas.clear();
    },
    image(){
      this.resizeCanvas();
    },
    readyForCanvas(isReady){
      if(isReady === true){
        let canvas = this.$store.getters.canvas;
        let vm = this;
        setTimeout(() =>{
          if(canvas === null){
            vm.createCanvas();
          }
          vm.loadCanvas();
        }, 50);
      }
    }
  },
  created() {
    window.addEventListener( 'resize', this.resizeCanvas );
  }
};
</script>
