<template>
  <div class="p-2 mb-1">
    <strong class="d-block mb-1">Type</strong>
    <div class="form-group">
      <select class="custom-select" v-on:change="changeType">
        <option :value="type.value" v-for="(type, index) in types" :key="'type'+index" :selected="type.value === selectedType.value">
          {{ type.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypeVariants',
  props:['types', 'selectedType'],
  methods:{
    changeType(event){
      this.$emit('changeType', event.target.value);
    }
  }
}
</script>