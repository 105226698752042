<template>
  <div class="btn-toolbar">
    <div class="d-flex">
      <input id="graphicColor" class="form-control" type="color" v-model="selectedColor">
    </div>
  </div>
</template>

<script>

export default {
  name: 'GraphicsToolbar',
  data(){
    return{
      selectedColor: '#000000'
    }
  },
  watch:{
    selectedColor(){
      this.$emit('setGraphicColor', this.selectedColor );
    }
  }
};
</script>
