<template>
  <form>
  <div class="border-bottom d-flex justify-content-between">
    <h5>{{ getName }}</h5>
    <h5>{{ selectedVariant.color.name }} / {{ selectedVariant.size.name }}</h5>
  </div>
  <div class="border-top pt-1 w-100">
    <div class="d-flex justify-content-between border-bottom py-1">
      <span>Base price</span>
      <span>{{ basePrice }}</span>
    </div>
    <div class="d-flex justify-content-between border-bottom py-1">
      <span>Graphics price <small class="text-muted">({{ addedGraphics.length }})</small></span>
      <span>{{ graphicsPrice }}</span>
    </div>
    <div class="d-flex justify-content-between border-bottom py-1">
      <span>Text price <small class="text-muted">({{ addedTexts.length }})</small></span>
      <span>{{ textPrice }}</span>
    </div>
    
    <div class="d-flex justify-content-between border-bottom border-top py-1">
      <strong>Total price</strong>
      <strong>{{ totalPrice }}</strong>
    </div>
    <div class="mt-3">
      <div class="input-group mb-3">
        <input type="number" class="form-control" placeholder="" v-model="quantity">
        <div class="input-group-append">
          <button class="btn btn-success" type="button" v-on:click="createOrderLine">Add to cart</button>
        </div>
      </div>
      
    </div>
  </div>
    <hr>
  </form>
</template>

<script>
import { utils } from '@/utils';
import html2canvas from 'html2canvas';
export default {
  name: 'Form',
  props:['selectedType', 'selectedVariant', 'variantCombos', 'addedGraphics', 'addedTexts'],
  data(){
    return {
      quantity: 1
    }
  },
  computed:{
    getBasePrice() {
      let productData = this.$store.getters.productData.find( p => p.type === this.selectedType.value );
      console.log(productData);
      const colorEntry = productData.variantGroups.colors.options.find( vo => vo.id === this.selectedVariant.color.id);
      const sizeEntry = productData.variantGroups.sizes.options.find( vo => vo.id === this.selectedVariant.size.id);
      return colorEntry.price || sizeEntry.price || productData.price || 0;
    },
    getName(){
      let productData = this.$store.getters.productData.find( p => p.type === this.selectedType.value );
      return productData.name;
    },
    getGraphicsPrice() {
      let graphicPrice = this.$store.getters.config.ecommerce.graphicPrice;
      return this.addedGraphics.length * graphicPrice;
    },
    getTextPrice() {
      let textPrice = this.$store.getters.config.ecommerce.textPrice;
      return this.addedTexts.length * textPrice;
    },
    getTotalPrice(){
      let basePrice = this.getBasePrice;
      let graphicsPrice = this.getGraphicsPrice;
      let textPrice = this.getTextPrice;
      return ((basePrice + graphicsPrice + textPrice) * this.quantity);
    },
    getTotalUnitPrice(){
      let basePrice = this.getBasePrice;
      let graphicsPrice = this.getGraphicsPrice;
      let textPrice = this.getTextPrice;
      return (basePrice + graphicsPrice + textPrice);
    },
    basePrice() {
      let price = this.getBasePrice;
      let currency = this.$store.getters.config.ecommerce.currency;
      return utils.formatPrice( price, currency );
    },
    graphicsPrice() {
      let price = this.getGraphicsPrice;
      let currency = this.$store.getters.config.ecommerce.currency;
      return utils.formatPrice( price, currency );
    },
    textPrice() {
      let price = this.getTextPrice;
      let currency = this.$store.getters.config.ecommerce.currency;
      return utils.formatPrice( price, currency );
    },
    totalPrice() {
      let price = this.getTotalPrice;
      let currency = this.$store.getters.config.ecommerce.currency;
      return utils.formatPrice( price, currency );
    }
  },
  methods:{
    
    createOrderLine(){
      const vm = this;
      let quantity = this.quantity;
      let selectedColor = this.selectedVariant.color;
      let selectedSize = this.selectedVariant.size;
      let productData = this.$store.getters.productData.find( p => p.type === this.selectedType.value );
      let addedGraphics = this.addedGraphics;
      let addedTexts = this.addedTexts;
      const canvas = this.$store.getters.canvas;

      this.$emit('toggleCanvasLoading');

      canvas.discardActiveObject().renderAll();
      
      let orderLineImage;
      const canvasContainer = document.getElementById('canvas-base');
      const bounds = canvasContainer.querySelector('.canvas-bounds');
      const boundsBorderStyle = bounds.style.borderColor;
      const boundsOutlineStyle = bounds.style.outlineColor;
      const boundsShadowStyle = bounds.style.boxShadow;
      bounds.style.borderColor = 'transparent';
      bounds.style.outlineColor = 'transparent';
      bounds.style.boxShadow = 'none';

      html2canvas(canvasContainer).then(function(convertedImage) {
        orderLineImage = convertedImage.toDataURL();
        bounds.style.border = boundsBorderStyle;
        bounds.style.outline = boundsOutlineStyle;
        bounds.style.boxShadow = boundsShadowStyle;

        const orderLine = {
          id: 'OL.' +  utils.guid(),
          product: productData,
          image: orderLineImage,
          variantId: [selectedColor.id, selectedSize.id].join('.'),
          productId: productData.id,
          color:selectedColor,
          size: selectedSize,
          graphics: addedGraphics,
          texts: addedTexts,
          price: vm.getTotalPrice,
          unitPrice: vm.getTotalUnitPrice,
          quantity: quantity,
          design: canvas.toDataURL(),
          bounds: bounds.style.cssText,
          _canvas: canvas
        };

        canvas.clear();
        vm.$emit('resetDesign');
        vm.$store.commit('addOrderLine', orderLine);
        vm.$emit('toggleCanvasLoading');
      });
      
      
      
      
    }
  }
};
</script>
